var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('td',[(_vm.instance)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-4",attrs:{"color":_vm.getAppointmentStatusColor(_vm.instance.status),"x-small":""}},on),[_vm._v(" fas fa-calendar ")])]}}],null,false,2807073185)},[(_vm.instance.status === 0)?[(_vm.isInstancePassed)?[_vm._v("Rendez-vous passé")]:[_vm._v(_vm._s(_vm.getAppointmentStatusLabel(_vm.instance.status)))],_c('br'),_vm._v(" Le "+_vm._s(_vm._f("toDayMonth")(_vm.instance.startDate))+" à "+_vm._s(_vm._f("toTime")(_vm.instance.startDate,false))+" ")]:[_vm._v(" "+_vm._s(_vm.getAppointmentStatusLabel(_vm.instance.status))+" ")]],2):_vm._e()],1),_c('td',[_vm._v(" "+_vm._s(_vm.buyer.name)+" "+_vm._s(_vm.buyer.firstname)+" "),(_vm.instance && _vm.instance.status === 0 && !_vm.isInstancePassed)?_c('ConfirmationDialog',{attrs:{"text":"Voulez-vous vraiment annuler ce rendez-vous ?"},on:{"confirm":function($event){return _vm.cancelInstance()}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"red","x-small":"","text":"","icon":""}},Object.assign({}, dialog, tooltip)),[_c('v-icon',[_vm._v(" mdi-calendar-remove ")])],1)]}}],null,true)},[_vm._v(" Annuler le rendez-vous ")])]}}],null,false,3323347951)}):_vm._e()],1),_c('td',[(!_vm.instance)?_c('ConfirmationDialog',{attrs:{"text":"Voulez-vous vraiment retirer cet acquéreur de la session de rendez-vous ?"},on:{"confirm":function($event){return _vm.removeBuyer(_vm.buyer.email)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"color":"red","x-small":"","text":"","icon":""}},Object.assign({}, dialog, tooltip)),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" fas fa-times ")])],1)]}}],null,true)},[_vm._v(" Supprimer ")])]}}],null,false,4177656618)}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }